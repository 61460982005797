import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IoMoonOutline, IoSunnyOutline } from 'react-icons/io5';

import { Button } from '@components/Button';
import { ButtonStyle } from '@components/Button/Button';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { TooltipWrapper } from '@components/Tooltip/Tooltip';
import { mergeStyles } from '@lib/styles';
import { log } from '@lib/utils';

export function DarkMode({
  noLabel = false,
  className,
}: {
  noLabel?: boolean;
  className?: string;
}) {
  const [darkMode, setDarkMode] = useState(false);
  const {
    displayDarkMode: [darkModeIsOn, setDarkModeIsOn],
  } = useContext<UIContextInterface>(UIContext);

  // set dark mode on and store in localStorage and UIContext
  const setDark = useCallback(() => {
    setDarkMode(true);
    document.documentElement.classList.add('dark');
    window.localStorage.setItem('theme', 'dark');
    setDarkModeIsOn(true);
  }, [setDarkModeIsOn]);

  // set light mode on and store in localStorage and UIContext
  const setLight = () => {
    setDarkMode(false);
    document.documentElement.setAttribute('data-theme', 'light');
    document.documentElement.classList.remove('dark');
    window.localStorage.setItem('theme', 'light');
    setDarkModeIsOn(false);
  };

  // check for dark mode on page load
  // from localStorage and user's preference
  useEffect(() => {
    try {
      const storedTheme = localStorage.getItem('theme');

      const prefersDark =
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches;

      const defaultDark =
        storedTheme === 'dark' || (storedTheme === null && prefersDark);

      if (defaultDark) {
        setDark();
      }
    } catch (err) {
      log({ error: err, location: 'DarkMode useEffect' });
    }
  }, [setDark]);

  const toggleTheme = () => {
    if (darkMode) {
      setLight();
    } else {
      setDark();
    }
  };

  const getLabel = () => {
    if (noLabel) return 'Toggle Dark Mode';
    if (darkMode) return 'Light Mode';
    return 'Dark Mode';
  };

  return (
    <TooltipWrapper text={getLabel()}>
      <Button
        id="nav-dark-mode-icon"
        onClick={toggleTheme}
        buttonStyle={ButtonStyle.tertiary}
        className={mergeStyles(className ?? '', 'w-10')}
        icon={
          darkMode ? (
            <IoSunnyOutline size="1.75rem" />
          ) : (
            <IoMoonOutline size="1.75rem" />
          )
        }
      />
    </TooltipWrapper>
  );
}
