import { useEffect } from 'react';
import useSWR from 'swr';

import { BulkAPIErrorResponse } from '@interfaces/BulkAPI';
import { BaseProduct } from '@interfaces/Product';
import { SWR_KEYS } from '@lib/constants';
import { log, logBreadcrumb } from '@lib/utils';

export function useOrders(): {
  data: BaseProduct[] | null;
  isLoading: boolean;
  error: any;
} {
  const apiUrl = new URL('/api/customer/productsOrdered', 'https://a');

  const fetcher = async (url) => {
    const res = await fetch(apiUrl.pathname + apiUrl.search).then((res) =>
      res.json()
    );
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  };

  // Get wishlist
  const { data, error, isLoading } = useSWR<BaseProduct[]>(
    SWR_KEYS.productsOrdered,
    fetcher,
    {
      dedupingInterval: 500,
      onErrorRetry: async (error, key, config, revalidate, { retryCount }) => {
        log({ error, location: 'useOrders' });

        // Only retry up to 3 times.
        if (retryCount >= 3) return;

        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
    }
  );
  useEffect(
    () =>
      logBreadcrumb({
        category: 'useOrders',
        message: `received new orders data from BigCommerce`,
        level: 'info',
        data: data,
      }),
    [data]
  );

  return { data: data ?? null, isLoading, error };
}
